﻿ .profUsername {
  color: #fff;
  float: right;
  height: 12px;
  margin-left: 25px;
  margin-right: 5%;
  padding-right: 10px;
  font-size: 12px;
}

.proPortBreadCr {
  color: #fff;
  background-color: #0091ca;
  margin-left: 25px;
  margin-right: 5%;
  font-size: 16px;
}

a:link, a:visited {
  z-index: 10;
}

.lblL {
  color: #4c75a8;
  font-size: 1.2em;
  font-weight: bold;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.pt {
  vertical-align: top;
  font-size: 1.1em;
  font-weight: bold;
}

.pt:hover, .stat:hover {
  cursor: pointer;
  cursor: hand;
}

.chgCursor {
  height: 28px;
}

.chgCursor:hover {
  cursor: pointer;
  cursor: hand;
}

.radK {
  color: #999;
  margin-left: 3px;
  font-size: .9em;
  font-weight: normal;
}

.radK:hover {
  cursor: pointer;
  cursor: hand;
}

.gvHdr {
  color: #9fc6e3;
  background-color: #fff;
  font-size: 1.2em;
}

.ctlDiv {
  background-color: #efefef;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tdHeight, .rowStyle {
  height: 20px;
  max-height: 20px;
  line-height: 20px;
}

.noWrap {
  white-space: nowrap;
  overflow: hidden;
}

.clr {
  clear: both;
}

.admin {
  color: #7fff00;
  font-family: Consolas, Courier New;
  font-size: 2em;
  font-weight: bold;
}

.softCorners {
  border-radius: 8px;
}

.spacer {
  width: 190px;
  height: 0;
}

.addlPanel {
  margin-left: -20px;
}

.imgAddl {
  float: left;
  vertical-align: middle;
  border: none;
  height: 24px;
  margin-top: 10px;
}

.infoNeededTxt {
  color: red;
  font-weight: bold;
  line-height: 42px;
}

.spacer1 {
  float: left;
  vertical-align: middle;
  border: none;
  width: 8px;
  height: 40px;
}

.addlOl {
  color: #666;
  float: left;
  font-weight: bold;
}

.clear {
  clear: both;
}

h2 {
  color: #3f8ec7;
  font-size: 2em;
  font-weight: normal;
}

.loginPanels {
  height: 370px;
  margin: 0 auto;
}

.ltLoginBox {
  text-align: center;
  color: #53534a;
  -moz-border-radius-topleft: 9px;
  -moz-border-radius-bottomleft: 9px;
  background-color: #eee;
  border: 2px solid #fff;
  -webkit-border-top-left-radius: 9px;
  -webkit-border-bottom-left-radius: 9px;
  min-height: 430px;
  padding: 20px 20px 0;
  font-size: 14px;
}

.rtLoginBox {
  float: left;
  color: #53534a;
  -moz-border-radius-topright: 9px;
  -moz-border-radius-bottomright: 9px;
  background-color: #fafafa;
  border: 2px solid #fff;
  -webkit-border-top-right-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  min-height: 430px;
  padding: 20px 10px 0 20px;
  font-size: 14px;
}

.regPanel {
  width: 610px;
  height: 700px;
  margin: 0 auto;
  padding: 20px 20px 0 0;
  position: relative;
  top: 50px;
}

.regSignupBox {
  color: #53534a;
  background-color: #eee;
  font-size: 14px;
}

.moveAble {
  position: absolute;
}

@media (max-width: 1000px) {
  .foo {
    margin-bottom: 99px;
  }
}

.btnGbsBlue {
  -webkit-appearance: button;
  text-rendering: auto;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  word-spacing: normal;
  color: #fff;
  border-image: initial;
  text-indent: 0;
  text-shadow: none;
  cursor: default;
  background-color: #32a4d4;
  border-style: none;
  border-width: 0;
  border-radius: .3rem;
  align-items: flex-start;
  max-width: 350px;
  margin: 0;
  padding: .5rem 1rem;
  font-family: sans-serif;
  font-size: .9rem;
  line-height: 1.2;
  display: inline-block;
  overflow: visible;
  -webkit-writing-mode: horizontal-tb !important;
}

.btnGbsBlue:hover {
  background-color: #288eb9;
}

.btnGbsGreen {
  -webkit-appearance: button;
  text-rendering: auto;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  word-spacing: normal;
  color: #fff;
  border-image: initial;
  text-indent: 0;
  text-shadow: none;
  cursor: default;
  background-color: #8bbc07;
  border-style: none;
  border-width: 0;
  border-radius: .3rem;
  align-items: flex-start;
  max-width: 350px;
  margin: 0;
  padding: .5rem 1rem;
  font-family: sans-serif;
  font-size: .9rem;
  line-height: 1.2;
  display: inline-block;
  overflow: visible;
  -webkit-writing-mode: horizontal-tb !important;
}

.btnGbsGreen:hover {
  background-color: #709706;
}

.submitButton {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(.05, #91bfe1), to(#348ccb));
  background: -moz-linear-gradient(center top, #91bfe1 5%, #348ccb 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#91bfe1", endColorstr= "#348ccb");
  -moz-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#91bfe1", endColorstr= "#348ccb");
  -o-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#91bfe1", endColorstr= "#348ccb");
  color: #fff;
  cursor: pointer;
  background-color: #348ccb;
  margin-top: 25px;
  padding: 10px 40px 12px;
  font-size: 1.2em;
  font-weight: normal;
  display: inline-block;
}

.submitButton:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(.05, #348ccb), to(#91bfe1));
  background: -moz-linear-gradient(center top, #348ccb 5%, #ededed 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#348ccb", endColorstr= "#91bfe1");
  -moz-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#348ccb", endColorstr= "#91bfe1");
  -o-filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#348ccb", endColorstr= "#91bfe1");
  background-color: #91bfe1;
}

.submitButton:active {
  position: relative;
  top: 1px;
}

.text-box {
  background-color: #fafafa;
  border: none;
  width: 375px;
  height: 30px;
  padding: 5px;
  font-size: 1.8em;
  display: inline-block;
}

.textBoxReg, .ddlReg {
  background-color: #fafafa;
  border: none;
  width: 300px;
  padding: 5px;
  font-size: 1.4em;
  display: inline-block;
}

input[type="checkbox"] {
  cursor: pointer;
  font-size: 1.5em;
  display: inline-block;
  transform: scale(1.5);
}

.ddl {
  background-color: #f5f5f5;
  border: none;
  width: 150px;
  height: 26px;
  font-size: 1em;
}

.em {
  color: red;
  font-size: 1.1em;
  font-weight: bold;
}

.loginErr {
  color: red;
  width: 700px;
  margin: 0 auto;
  padding-bottom: 40px;
  font-size: 1.4em;
  position: relative;
  top: 100px;
}

/*# sourceMappingURL=proPortal.css.map */
