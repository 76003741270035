﻿    .profUsername {
        height:12px;
        font-size:12px;
        color:#ffffff;
        margin-left:25px;
        margin-right:5%;
        padding-right:10px;
        float:right;
    }
    .proPortBreadCr {
        font-size:16px;
        background-color:#0091ca;
        color:#ffffff;
        margin-left:25px;
        margin-right:5%;
    }
    a:link {
        z-index:10;
    }
    a:visited {
        z-index:10;
    }
    .lblL {
        color: #4c75a8;
        font-weight: bold;
        font-size: 1.2em;
    }
    .tac {
        text-align: center;
    }
    .tar {
        text-align: right;
    }
    .pt {
        vertical-align:top;
        font-weight: bold;
        font-size: 1.1em;
    }
    .pt:hover {
        cursor: pointer; cursor: hand;
    }
    .stat:hover {
        cursor: pointer; cursor: hand;
    }
    .chgCursor {
        height:28px;
    }
    .chgCursor:hover {
        cursor: pointer; cursor: hand;
    }
    .radK {
        font-weight: normal;
        font-size:0.9em;
        color: #999999;
        margin-left: 3px;
    }
    .radK:hover {
        cursor: pointer; cursor: hand;
    }
    .gvHdr {
        background-color: #ffffff;
        color: #9fc6e3;
        font-size: 1.2em;
    }    
    .ctlDiv {
        background-color:#efefef; 
        margin-left:10%; 
        margin-right:10%; 
        padding-top:10px; 
        padding-bottom:10px;
    }
    .tdHeight {
        height:20px;
        line-height:20px;
        max-height:20px;
    }
    .rowStyle {
        height:20px;
        line-height:20px;
        max-height:20px;
    }
    .noWrap {
        white-space: nowrap;
        overflow: hidden;
    }
    .clr {
        clear:both;
    }
    .admin {
        font-size:2.0em;
        font-family:Consolas,'Courier New';
        font-weight:bold;
        color:chartreuse;
    }
    .softCorners {
        border-radius:8px;
    }
    .spacer {
        width:190px;
        height:0px;
    }
    .addlPanel {
        margin-left:-20px;
    }
    .imgAddl {
        border:none;
        margin-top:10px;
        height:24px;
        float:left;
        vertical-align:middle;
    }
    .infoNeededTxt {
        line-height:42px;
        color:red;
        font-weight:bold;
    }
    .spacer1 {
        width:8px;
        border:none;
        height:40px;
        float:left;
        vertical-align:middle;
    }
    .addlOl {
        font-weight:bold;
        color:#666666;
        float:left;
    }
   .clear {
        clear: both;
    }
   h2 {
       color:#3f8ec7;
       font-size:2.0em;
       font-weight:normal;
   }
    .loginPanels {
        margin:0 auto;
        height:370px;
    }
    .ltLoginBox {        
        text-align:center;
        background-color: #eeeeee;
        border: 2px solid #ffffff;
        color: #53534a;
        font-size: 14px;
        min-height:430px;
        padding: 20px 20px 0px 20px;
        -moz-border-radius-topleft:9px;
        -moz-border-radius-bottomleft:9px;
        -webkit-border-top-left-radius:9px;
        -webkit-border-bottom-left-radius:9px;
    }
   .rtLoginBox {
       float:left;
       border:2px solid #ffffff;
       background-color:#fafafa;
       color:#53534a;
       font-size:14px;
       min-height:430px;
       padding:20px 10px 0px 20px;
       -moz-border-radius-topright:9px;
       -moz-border-radius-bottomright:9px;
       -webkit-border-top-right-radius:9px;
       -webkit-border-bottom-right-radius:9px;
   }
   .regPanel {
       position:relative;
       width:610px;
       height:700px;
       margin:0 auto;
       top:50px;
       padding:20px 20px 0px 0px;
   }
   .regSignupBox {
        background-color:#eeeeee;
        color:#53534a;
        font-size:14px;
   }

   .moveAble {
    position: absolute;
}
/* START 2022 Green/Blue reskin scheme*/
@media all and (max-width: 1000px)  {
    .foo {
        margin-bottom: 99px;
    }
}
    .btnGbsBlue {
        -webkit-appearance: button;
        -webkit-writing-mode: horizontal-tb !important;
        padding: .5rem 1rem;
        line-height: 1.2;
        border-radius: .3rem;
        text-rendering: auto;
        text-align: center;
        text-transform: none;
        font-size: 0.9rem;
        font-family: sans-serif;
        letter-spacing: normal;
        word-spacing: normal;
        color: #fff;
        background-color: #32a4d4;
        border-image: initial;
        overflow: visible;
        margin: 0;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        align-items: flex-start;
        cursor: default;
        border-width: 0px;
        border-style: none;
        max-width:350px;
    }
    .btnGbsBlue:hover {
        background-color: #288eB9;
    }
.btnGbsGreen {
    -webkit-appearance: button;
    -webkit-writing-mode: horizontal-tb !important;
    padding: .5rem 1rem;
    line-height: 1.2;
    border-radius: .3rem;
    text-rendering: auto;
    text-align: center;
    text-transform: none;
    font-size: 0.9rem;
    font-family: sans-serif;
    letter-spacing: normal;
    word-spacing: normal;
    color: #fff;
    background-color: #8BBC07;
    border-image: initial;
    overflow: visible;
    margin: 0;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    align-items: flex-start;
    cursor: default;
    border-width: 0px;
    border-style: none;
    max-width: 350px;
}

    .btnGbsGreen:hover {
        background-color: #709706;
    }

/* END 2022 Green/Blue reskin scheme*/
    
    .submitButton {
        background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #91bfe1), color-stop(1, #348ccb) );
        background: -moz-linear-gradient( center top, #91bfe1 5%, #348ccb 100% );
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91bfe1', endColorstr='#348ccb');
        -moz-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91bfe1', endColorstr='#348ccb');
        -o-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91bfe1', endColorstr='#348ccb');
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91bfe1', endColorstr='#348ccb');
        background-color: #348ccb;
        display: inline-block;
        color: #fff;
        font-size: 1.2em;
        font-weight: normal;
        padding: 10px 40px 12px 40px;
        cursor: pointer;
        margin-top: 25px;
    }

    .submitButton:hover {
        background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #348ccb), color-stop(1, #91bfe1) );
        background: -moz-linear-gradient( center top, #348ccb 5%, #ededed 100% );
        -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#348ccb', endColorstr='#91bfe1');
        -moz-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#348ccb', endColorstr='#91bfe1');
        -o-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#348ccb', endColorstr='#91bfe1');
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#348ccb', endColorstr='#91bfe1');
        background-color: #91bfe1;
    }

    .submitButton:active {
        position: relative;
        top: 1px;
    }
    .text-box {
        font-size:1.8em;
        padding:5px;
        border:none;
        background-color:#fafafa;
        height:30px;
        width:375px;
        display: inline-block;
    }
    .textBoxReg, .ddlReg {
        font-size:1.4em;
        padding:5px;
        border:none;
        background-color:#fafafa;
        width:300px;
        display: inline-block;
    }
    input[type="checkbox"] {
        cursor: pointer;
        display: inline-block;
        transform: scale(1.5);
        font-size:1.5em;
    }
    .ddl {
        width: 150px;
        font-size: 1em;
        height: 26px;
        border: none;
        background-color: #f5f5f5;
    }
    .em {
        color:#ff0000;
        font-size:1.1em;
        font-weight:bold;
    }
    .loginErr {
        position:relative;
        top:100px;
        width:700px;
        margin:0 auto;
        color:#ff0000;
        font-size:1.4em;
        padding-bottom:40px;
    }